<template>
	<div class="layout-topbar">
		<Toast />
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" />
			<span style="color:white">eTicket</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars" style="color:white"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<!-- <button :class="{ 'glowing-bell': notification_count > 0 }" @click="toggleNotification">
					<i class="pi pi-bell"></i>
				</button> -->
				<button :class="{ 'glowing-bell': notification_count > 0, 'p-link': true, 'layout-topbar-button': true }" @click="toggleNotification">
					<i class="bell pi pi-bell" v-if="notification_count>0"></i>
                    <i class="pi pi-bell" v-else></i>
					
				</button>
				
				&nbsp;&nbsp;
				<Menu ref="notification" style="width: 20% !important;height: 80% !important; overflow: scroll" :model="overlayNotificationItems" :popup="true" >
					<template #end v-if="notification_count>0" >
						<div class="col-12">
						<div class="grid justify-content-between">
							<div class="col-12 md:col-6">
								<div
								class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
								>
								<p style="color: #6b719b; font-size: 15px; font-weight: 600">
								{{notification_count}}	Notifications
								</p>
								</div>
							</div>
							<hr>
							<div class="col-12 md:col-6">
								<div
								class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
								>
								<p style="color: #6b719b; font-size: 14px; font-weight: 650;cursor: pointer;" @click="mark_as_read({},1)">
									Mark All As Read
								</p>
								</div>
							</div>
							</div>
						</div>
						
						<div class="col-12" v-for="(item, index) in notification_list" :key="index">

							<div class="p-fluid formgrid grid hover" @click="mark_as_read(item)" style="cursor: pointer;">
								<div class="field col-12 md:col-2" >
									<div class="flex align-items-center">
										<span class="i-circle-extra_small"  :style="'background-color:#00B0B0'">#</span>
									</div>
									
								</div>
								<div class="field col-12 md:col-10">
									<p class="font-bold">{{item.title}}</p>
									<p >{{item.description}}</p>
									<!-- <p style="text-align: right;color: #6b719b; font-size: 15px; font-weight: 400">{{ item.date }}</p> -->
								</div>
							</div>
							<hr>
						</div>
					
					</template>
					<template #end v-else>
						<div class="col-12 md:col-12" style="margin-top:25%"></div>
						
						<div class="col-12 md:col-12">
							<div class="center">
								<span class="d-flex center i-circle"  :style="'background-color:#00B0B0'">?</span>
							</div>
							
							<h4 class="d-flex text-center" style="color: #6b719b; font-size: 20px; font-weight: 800">
								We couldn't find any notifications
							</h4>
						</div>
					</template>
				</Menu>
			</li>
			
			<li >
				<img style="border-radius: 50%; width: 40px; height: 40px;" :src="profile_photo" :alt="profile_photo" v-if="profile_photo">
				<span v-else-if="user_name" class="i_circle_extra_small_no_shadow" :style="'background-color:'+getcolorcode(user_name)">{{convert_name(user_name)}}</span>
			</li>
			
			<li style="margin-top: 5%;">
				
				<label style="color:white;cursor: pointer;" for="" @click="toggleMenu">Hi, {{ user_name }}</label>
				<i class="pi pi-angle-down" style="color:white;cursor: pointer;" @click="toggleMenu"></i>
				<Menu ref="menu" :model="overlayMenuItems" :popup="true" >
						<template #end>
                <button @click="logout" v-ripple class="relative overflow-hidden w-full p-link  align-items-center p-2 pl-3 text-color hover:surface-200 border-noround">
					<i style="color: red;" class="pi pi-sign-in"></i>&nbsp;&nbsp;
                    <span class="inline-flex flex-column">
                        <span style="color: red;" class="font-bold">Logout</span>
                        
                    </span>
                </button>
            </template>
					</Menu>
			</li>

			
			
		</ul>
		<Dialog
          v-model:visible="UpdateEmailDialog"
		  :breakpoints="{'960px': '75vw'}"
          :style="{ width: '40vw' }"
          header="Email Setting"
          :modal="true"
		  position="top"
          maximizable 
        >
		<div class="col-12">
			<div class="p-fluid formgrid grid" >
				<div class="field col-12 md:col-1" >
					<InputSwitch v-model="checked" />
				</div>
				<div class="field col-12 md:col-11" >
					<label for="name">Send me ticket update through Email</label>
				</div>
				<div class="field col-12 md:col-12" >
					<b>Email Cases :</b><br><br>
						<label for="">* You create ticket successfully will be notified by email</label><br>
						<label for="">* Receive reply on ticket then will be informs you by email</label>
				</div>
			</div>
		</div>
		<template #footer>
			<Button label="Save" @click="update_email" icon="pi pi-check" class="p-button-success"/>
		</template>
		</Dialog>
		<Dialog
          v-model:visible="UpdateUserDialog"
		  :breakpoints="{'960px': '75vw'}"
          :style="{ width: '60vw' }"
          header="Edit Profile"
          :modal="true"
		  position="top"
        >
		<div class="col-12">
			<div class="p-fluid formgrid grid" style="margin-top: 2%;">
				<div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="name" type="text" v-model="u_name"
                             />
                            <label for="name">Name</label>
                        </span>
                </div>
				<div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="email" type="text" v-model="email"
                             />
                            <label for="email">Email</label>
                        </span>
                </div>
				<div class="field col-12 md:col-6" style="margin-top: 1%;">
                        <span class="p-float-label">
                            <InputText id="mobile_no" type="number" v-model="mobile_no"
                             />
                            <label for="mobile_no">Mobile No</label>
                        </span>
                </div>
				<div class="field col-12 md:col-3" style="margin-top: 1%;">
                        <FileUpload
                            
                            class="p-button-primary"
                            mode="basic"
                            name="model[]"
                            
                            :auto="true"
                            :custom-upload="true"
                            choose-label="Profile Photo"
                            @uploader="handleFileUpload($event)"
                        />
                </div>
				<div class="field col-12 md:col-3" style="margin-top: 1%;">
					<img :src="profile_photo" :alt="profile_photo" v-if="profile_photo"
                                        class="shadow-2" width="70">
                    <img v-else src="https://media.istockphoto.com/vectors/no-image-available-icon-vector-id1216251206?k=6&m=1216251206&s=612x612&w=0&h=G8kmMKxZlh7WyeYtlIHJDxP5XRGm9ZXyLprtVJKxd-o="
                                        class="shadow-2" width="70" />
                </div>
					
			</div>
		</div>
		<template #footer>
		<Button label="Save" @click="update_user" icon="pi pi-check" class="p-button-success"/>
		</template>
		</Dialog>

		<Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px', width: '450px' }"
          header="Change Password"
          :modal="true"
          class="p-fluid"
		  position="top"
        >
		
		 	<!-- <label for="password1" class="block text-900 font-medium text-xl mb-2">Old Password</label>
                <Password id="password1" v-model="old_password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
						 
			<label for="password1" class="block text-900 font-medium text-xl mb-2">New Password</label>
                <Password id="password1" v-model="new_password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
						 
			<label for="password1" class="block text-900 font-medium text-xl mb-2">Confirm Password</label>
                <Password id="password1" v-model="conf_password" placeholder="Password" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
						
			<Button label="Change Password" class="p-button-rounded p-button-success" @click="change()" /> -->

			<div class="col-12">
				<div class="p-fluid formgrid grid" style="margin-top: 2%">
					<div class="field col-12 md:col-12" style="margin-top: 2%">
						<span class="p-float-label">
							<InputText id="password1" v-model="old_password" type="password" />
							<label>Old Password</label>
						</span>
					</div>
					<div class="field col-12 md:col-12" style="margin-top: 2%">
						<span class="p-float-label">
							<InputText id="new_password" v-model="new_password" type="password" />
							<label>New Password</label>
						</span>
					</div>
					<div class="field col-12 md:col-12" style="margin-top: 2%">
						<span class="p-float-label">
							<InputText id="conf_password" v-model="conf_password" type="password" />
							<label>Confirm Password</label>
						</span>
					</div>
					<div class="field col-12 md:col-12" style="margin-top: 2%">
						<Button label="Change Password" class="p-button-rounded p-button-primary" @click="change()" />
					</div>
				</div>
			</div>
		</Dialog>
	</div>

	
</template>

<script>
import apis from "@/apis";
import axios from "axios";
import ColorService from "./service/ColorService";
export default {
	data() {
		
			return {
				ColorService:null,
				color_data:[],
				notification_count:0,
				notification_list:[],
				user_name:"",
				profile_photo:"",
				checked:true,
				u_name:"",
				email:"",
				mobile_no:"",
				file:"",
				file_data:{
					filePath:"",
                	contentType: "",
				},
				attachments:"",
				UpdateEmailDialog:false,
				UpdateUserDialog:false,
				conf_password: "",
				new_password: "",
				old_password: "",
				productDialog:false,
				name:"",
				overlayNotificationItems:[],
				overlayMenuItems: [
					{
						label: 'Edit Profile',
						icon: 'pi pi-user',
						command: () => {
							
							this.UpdateUserDialog = true;
						}
					},
					{
						label: 'Email Setting',
						icon: 'pi pi-cloud-upload',
						command: () => {
							
							this.UpdateEmailDialog = true;
						}
					},
					{
						label: 'Change Password',
						icon: 'pi pi-lock',
						command: () => {
							this.conf_password= "";
							this.new_password= "";
							this.old_password= "";
							this.productDialog = true;
							
          				}
					}
					// ,
					// {
					// 	label: 'Logout',
					// 	icon: 'pi pi-sign-in',
					// 	to:'/',
						
					// 		command: () => {
									
					// 			localStorage.clear();
					// 			this.$router.push("/");
					// 			return false;
					// 	}
						
					// },
					
				],
			}
	},
    methods: {
		convert_name(name)
      {
        var x= this.ColorService.convertToSN(name);
        return x.toString();
        
      },
		getcolorcode(name)
      {
        var name=this.convert_name(name)
        var temp=name.substring(0, 1);
        for (let index = 0; index < this.color_data.length; index++) 
        {
         if (this.color_data[index].name==temp) 
         {
            return this.color_data[index].code;
         }
          
        }
      },
		async mark_as_read(item,flag)
		{	
			var details={};
			if (flag==1) 
			{
				 details={
					"user_id": localStorage.getItem("id"),
					"id":"",
					"read_all":'1'
				}
			}
			else
			{
				 details={
					"user_id": localStorage.getItem("id"),
					"id":item._id,
				}
			}
			

				var promise = apis.read_notification(details);
					promise.then((response) => {
						this.getnotificationlist();
						this.getnotificationcount();
					});
		},
		async logout()
		{
			localStorage.clear();
			this.$router.push("/");
			return false;
		},
		async update_email()
		{	
			var status="";
			if (this.checked==true) {
				status="Yes";
			} else {
				status="No"
			}
			let details={
					"user_id": localStorage.getItem("id"),
					"status":status,
    				"type":"Email"
				}

				var promise = apis.updateUserStatus(details);
					promise.then((response) => {
						this.$swal(response.data.message);
        				this.UpdateEmailDialog = false;
					});
		},	
		async update_user()
		{
			let details={
					"user_id": localStorage.getItem("id"),
					"email_id": this.email,
					"profile_image": this.attachments,
					"mobile_number": this.mobile_no,
					"name": this.u_name
				}

				var promise = apis.updateUserProfile(details);
					promise.then((response) => {
						this.$swal(response.data.message);
        				this.UpdateUserDialog = false;
					});
		},
		async date_time_prefix() 
        {
            // Get the current date and time
            const currentDateTime = new Date();

            // Format the date as YYYY_MM_DD
            const formattedDate = currentDateTime.toISOString().split('T')[0].replace(/-/g, '_');

            // Format the time as HH_mm_ss
            const formattedTime = currentDateTime.toTimeString().split(' ')[0].replace(/:/g, '_');

            // Construct the final string
            const resultString = `${formattedDate}_${formattedTime}`;

            return resultString;
        },
		async handleFileUpload(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                  this.attachments="https://eticket-docs.s3.ap-south-1.amazonaws.com/" +filename;
                  this.profile_photo=this.attachments;
                   
                });
            });

        },
		change()
		{
			
			 if (!this.old_password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Old password",
          life: 3000,
        });
        return false;
      }
	   if (!this.new_password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter New password",
          life: 3000,
        });
        return false;
      }
	   if (!this.conf_password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Confirm password",
          life: 3000,
        });
        return false;
      }
	  if (this.conf_password != this.new_password) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Confirm Password Not Match With Password",
          life: 3000,
        });
        return false;
      }
	 
	  
		//api
      	var data = {
		"user_id": localStorage.getItem("id"),
		"password":this.old_password,
		"new_password":this.new_password,
      };
    
      var promise = apis.passwordreset(data, this.id);
      promise.then((response) => {
     
			this.productDialog = false;
        	this.$swal(response.data.message);
        	this.update_pass_Dialog = false;
		 	localStorage.clear();
      		this.$router.push("/"); 
      }).catch((error) => {
            //console.log(error);
          	this.productDialog = false;
			this.$swal.fire(error.response.data);
         
          });

			


		
			
		},
			toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},
			async toggleNotification(event)
			{	
				await this.getnotificationlist();
				await this.getnotificationcount();
				this.$refs.notification.toggle(event);
			},
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/eTicket_logo_new.png' : 'images/eTicket_logo_new.png';
		},
		async getnotificationlist()
		{	
			var data = {    
							"user_id":localStorage.getItem("id"),
							"status":"Pending",
							"limit":10000,
							"page_no":1,
							"count":false
                       };
                        
                        var promise = apis.userNotification(data);
                        promise.then((response) => {
                            
                            this.notification_list = response.data.data;
                            console.log("🚀 ~ promise.then ~ list:", response.data.data)
                            
                        });
		},
		async getnotificationcount()
		{
			var data = {    
							"user_id":localStorage.getItem("id"),
							"status":"Pending",
							"count":true
                       };
                        
                        var promise = apis.userNotification(data);
                        promise.then((response) => {
                           
                            this.notification_count = response.data.data;
							if (this.notification_count > 0) 
							{
								document.querySelector('div').classList.add('glowing-div');
							} else 
							{
								document.querySelector('div').classList.remove('glowing-div');
							}
                            
                        });
		}
    },
		async mounted(){
			this.ColorService = new ColorService();
			this.ColorService.getColors().then((colors) => {
			this.color_data = colors;});
			
			this.name= localStorage.getItem("email");

			this.u_name=localStorage.getItem("name");
			this.email=localStorage.getItem("email_id");
			this.mobile_no=localStorage.getItem("mobile_number");
			
			var email_notification = localStorage.getItem("email_notification");
			if (email_notification=='Yes') {
				this.checked=true;
			} else {
				this.checked=false;
			}
			this.profile_photo=localStorage.getItem("profile_image");

			var user_type = localStorage.getItem("user_type");
			if (user_type=='Admin') {
				this.user_name='Admin';
			}
			else if(user_type=='Agenct')
			{
				this.user_name='Agent';
			}
			else{
				this.user_name=this.u_name;
			}
			await this.getnotificationlist();
			await this.getnotificationcount();

		},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
<style scoped>
.layout-topbar .layout-topbar-logo {
   
    width: 150px;
   
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.glowing-bell:hover {
	background-color: #274461 !important;
}
.glowing-bell {
    
    
    
    animation: glow 1s infinite alternate;
  }

  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px #ffffff;
    }
    to {
      box-shadow: 0 0 10px 5px #ffffff;
    }
  }

  .bell{
  display:block;
  /* width: 40px;
  height: 40px; */
  /* font-size: 40px; */
  /* margin:50px auto 0; */
  color:white;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .2s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}

</style>